body
{
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'QuicksandLight';
    font-size: x-large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./resources/tactile_noise.webp');
    background-repeat: repeat;
    background-attachment: fixed;
    background-size:auto;
    color: white;
}

h1 {
  font-family:'Morpheus'; 
  scale: 1;
  margin: 20px;
  color: #b11010;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DieNasty';
  src: url('../public/fonts/die nasty.otf') format('opentype');
}

@font-face {
  font-family: 'EvilDead';
  src: url('../public/fonts/EVILDEAD.TTF') format('truetype');
}

@font-face {
  font-family: 'Metropolitan';
  src: url('../public/fonts/Metropolitan.ttf') format('truetype');
}

@font-face {
  font-family: 'Morpheus';
  src: url('../public/fonts/MORPHEUS.TTF') format('truetype');
}

@font-face {
  font-family: 'NordicaBlack';
  src: url('../public/fonts/NordicaBlack.otf') format('opentype');
}

@font-face {
  font-family: 'NordicaHairline';
  src: url('../public/fonts/NordicaHairline.otf') format('opentype');
}

@font-face {
  font-family: 'NordicaRegular';
  src: url('../public/fonts/NordicaRegular.otf') format('opentype');
}

@font-face {
  font-family: 'NordicaThin';
  src: url('../public/fonts/NordicaThin.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandBold';
  src: url('../public/fonts/Quicksand_Bold.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandBoldOblique';
  src: url('../public/fonts/Quicksand_Bold_Oblique.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandBook';
  src: url('../public/fonts/Quicksand_Book.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandBookOblique';
  src: url('../public/fonts/Quicksand_Book_Oblique.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandDash';
  src: url('../public/fonts/Quicksand_Dash.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandLight';
  src: url('../public/fonts/Quicksand_Light.otf') format('opentype');
}

@font-face {
  font-family: 'QuicksandLightOblique';
  src: url('../public/fonts/Quicksand_Light_Oblique.otf') format('opentype');
}

@font-face {
  font-family: 'RootersItalic';
  src: url('../public/fonts/Rooters Italic.otf') format('opentype');
}

@font-face {
  font-family: 'RootersItalicTTF';
  src: url('../public/fonts/Rooters Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'RootersOTF';
  src: url('../public/fonts/Rooters.otf') format('opentype');
}

@font-face {
  font-family: 'RootersTTF';
  src: url('../public/fonts/Rooters.ttf') format('truetype');
}

@font-face {
  font-family: 'StrangerBackInTheNight';
  src: url('../public/fonts/Stranger back in the Night.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayBlackRevOblique';
  src: url('../public/fonts/Walkway Black RevOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayBlack';
  src: url('../public/fonts/Walkway Black.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayBoldRevOblique';
  src: url('../public/fonts/Walkway Bold RevOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayBold';
  src: url('../public/fonts/Walkway Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayCondensedBold';
  src: url('../public/fonts/Walkway Condensed Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayCondensedSemiBold';
  src: url('../public/fonts/Walkway Condensed SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayCondensed';
  src: url('../public/fonts/Walkway Condensed.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayExpandBlack';
  src: url('../public/fonts/Walkway Expand Black.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayExpandBold';
  src: url('../public/fonts/Walkway Expand Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayExpandRevOblique';
  src: url('../public/fonts/Walkway Expand RevOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayExpandSemiBold';
  src: url('../public/fonts/Walkway Expand SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayExpandUltraBold';
  src: url('../public/fonts/Walkway Expand UltraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayExpand';
  src: url('../public/fonts/Walkway Expand.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayObliqueBlack';
  src: url('../public/fonts/Walkway Oblique Black.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayObliqueBold';
  src: url('../public/fonts/Walkway Oblique Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayObliqueExpand';
  src: url('../public/fonts/Walkway Oblique Expand.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayObliqueSemiBold';
  src: url('../public/fonts/Walkway Oblique SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayObliqueUltraBold';
  src: url('../public/fonts/Walkway Oblique UltraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayOblique';
  src: url('../public/fonts/Walkway Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayRevOblique';
  src: url('../public/fonts/Walkway RevOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayRounded';
  src: url('../public/fonts/Walkway rounded.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwaySemiBoldRevOblique';
  src: url('../public/fonts/Walkway SemiBold RevOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwaySemiBold';
  src: url('../public/fonts/Walkway SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraBoldRevOblique';
  src: url('../public/fonts/Walkway UltraBold RevOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraBold';
  src: url('../public/fonts/Walkway UltraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraCondensedBold';
  src: url('../public/fonts/Walkway UltraCondensed Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraCondensedSemi';
  src: url('../public/fonts/Walkway UltraCondensed Semi.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraCondensed';
  src: url('../public/fonts/Walkway UltraCondensed.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraExpandBold';
  src: url('../public/fonts/Walkway UltraExpand Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraExpandSemiBold';
  src: url('../public/fonts/Walkway UltraExpand SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WalkwayUltraExpand';
  src: url('../public/fonts/Walkway UltraExpand.ttf') format('truetype');
}

@font-face {
  font-family: 'WhoAsksSatan';
  src: url('../public/fonts/who asks satan.ttf') format('truetype');
}
